import { Avatar, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import QuotesIcon from '../icons/QuotesIcon';

const FeedbackComment: React.FC = () => {
  return (
    <Flex pt={10} justifyContent="center" borderWidth={1} borderColor="#ECECEE">
      <Flex flexDirection="column" gap={4} width="50%">
        <QuotesIcon viewBox="0 0 60 50" boxSize="60px" />
        <Text fontSize="2xl" fontWeight="light">
          Analyze your sales team performance by contributing your own data. All data is aggregated and de-identified
          before its shown in benchmarking results - no individual identification. Drill down to similar comparables
          with meaningful filters.
        </Text>
        {false && (
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar name="Brian D." src="" />
              <HStack>
                <Heading size="sm">Brian D.</Heading>
                <Text fontWeight="light">Director of Revenue Operations</Text>
              </HStack>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default FeedbackComment;
