import { useQuery } from '@tanstack/react-query';

import { ChartFiltersType } from '@/types';
import api from '../api';

export function useChart<T>(
  url: string,
  chart: string,
  org_id: number,
  chartFilters: ChartFiltersType,
  survey_id?: number | undefined,
) {
  return useQuery<T, unknown>(
    [url, chart, org_id, chartFilters, survey_id],
    async () => {
      const searchParams = { ...chartFilters } as ChartFiltersType & { survey_id?: number };
      if (survey_id) searchParams.survey_id = survey_id;
      const params = new URLSearchParams({ ...searchParams } as Record<string, any>);
      const response = await api.get(`${url}/${org_id}/?${params.toString()}`);
      if (![200, 204].includes(response.status)) {
        throw new Error('Error fetching chart data');
      }

      return response.data as T;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour,
      enabled: !!url && !!chart && !!org_id,
      retry: false,
    },
  );
}
