import { Box, BoxProps, Button, Flex, Heading, Text } from '@chakra-ui/react';

import SurveyList from '../components/SurveyList';

interface SurveyListPageProps extends BoxProps {
  title: string;
  survey_type: string;
}

const SurveyListPage = (props: SurveyListPageProps) => {
  return (
    <Box p={20} overflowY={'scroll'}>
      <Flex direction="column">
        <Flex justifyContent="space-between">
          <Heading size="lg" fontWeight="medium" py={5}>
            {props.title}
          </Heading>
          <Button>
            <Text fontWeight="normal">New Survey</Text>
          </Button>
        </Flex>
        <SurveyList survey_type={props.survey_type} />
      </Flex>
    </Box>
  );
};

export default SurveyListPage;
