import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Popover: ComponentStyleConfig = {
  baseStyle: {
    popper: {
      // zIndex.mobileSidebar + 1
      zIndex: 1030,
    },
  },
};
