import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react';

import { Organization } from '@/types';
import { useStickyState } from '../hooks';

interface OrgFieldProps {
  name: string;
  value: string | number;
}

const OrgField = ({ name, value }: OrgFieldProps) => {
  return (
    <Flex direction="column">
      <Text fontWeight="medium" fontSize="lg" py={3}>
        {name}
      </Text>
      <Text fontWeight="normal">{value}</Text>
    </Flex>
  );
};

const HomeProfile = (props: FlexProps) => {
  const [org] = useStickyState<Organization>(null, 'org');
  return (
    <Flex direction="column" width="100%" px={20} {...props}>
      <Heading size="lg" fontWeight="bold">
        Profile
      </Heading>
      <Text fontWeight="normal" py={3}>
        Your profile information is used to allow for filtered comparisons of benchmarks.
      </Text>
      <Flex width="100%" justifyContent="space-between">
        {[
          { name: 'Industry', value: org.industry },
          { name: 'Employee Count', value: org.employees },
          { name: 'Annual Revenue', value: org.revenue },
          { name: 'Fiscal Year End', value: org.fiscal_year_end },
        ].map((data) => (
          <OrgField key={`key-profile-char-${data.name}`} {...data} />
        ))}
      </Flex>
    </Flex>
  );
};

export default HomeProfile;
