import { Box, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';

interface NivoTooltipProps extends BoxProps {
  data: {
    [key: string]: any;
  };
  absolute?: boolean;
  symbol?: string;
}

const NivoTooltip: React.FC<NivoTooltipProps> = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      backgroundColor="white"
      boxShadow="base"
      borderRadius={3}
      p={1}
      px={2}
      {...props}
    >
      <Box width={3} height={3} backgroundColor={props.data.color}></Box>
      <Text fontSize={16} mx={2}>
        {props.data.label}:
      </Text>
      <Text fontSize={16} fontWeight="bold">
        {`${props.absolute ? Math.abs(props.data.value) : props.data.value}${props.symbol || ''}`}
      </Text>
    </Box>
  );
};

export default NivoTooltip;
