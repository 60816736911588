import { Box, BoxProps, HStack, Select } from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import api from '../api';
import { useChartFilters } from '../contexts/ChartFiltersContext';
import { ChartFiltersType } from '../types';

type ChartFilter = {
  label: string;
  value: keyof ChartFiltersType;
  visible?: 'plan' | 'payee';
};

type ChartFilters = {
  industry: ChartFilter[];
  revenue: ChartFilter[];
  employees: ChartFilter[];
  plan_role: ChartFilter[];
  payee_role: ChartFilter[];
};

const fetchChartFilters = async () => {
  const response = await api.get<ChartFilters>('charts/filters/');
  if (response.status !== 200) {
    throw new Error('Error fetching user data');
  }

  return response.data;
};

const filters: ChartFilter[] = [
  { label: 'Industry', value: 'industry' },
  { label: 'Revenue', value: 'revenue' },
  { label: 'Employees', value: 'employees' },
  { label: 'Role', value: 'plan_role', visible: 'plan' },
  { label: 'Role', value: 'payee_role', visible: 'payee' },
];

const ChartFiltersBar = (props: BoxProps) => {
  const { data, error, isSuccess } = useQuery<ChartFilters, unknown>(['chart_filters'], fetchChartFilters, {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
  const { chartFilters, setChartFilters } = useChartFilters();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const survey_type = searchParams.get('survey_type');

  if (error || !isSuccess) return null;

  const SelectFilter = ({ label, value }: ChartFilter) => {
    return (
      <HStack width={'100%'} backgroundColor="transparent">
        {chartFilters[value] && (
          <FontAwesomeIcon
            type="button"
            icon={icon({ name: 'xmark' })}
            onClick={(e) => {
              delete chartFilters[value];
              setChartFilters({
                ...chartFilters,
              });
            }}
          />
        )}
        <Select
          placeholder={chartFilters[value] || label}
          size="md"
          onChange={(e) =>
            setChartFilters({
              ...chartFilters,
              [value]: e.target.value,
            })
          }
        >
          {data[value].map((filter, i) => (
            <option key={`chart-filter-option-${i}-${filter.value}`} value={filter.value}>
              {filter.label}
            </option>
          ))}
        </Select>
      </HStack>
    );
  };
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap={8} px={2} {...props}>
      {filters.map((filter) => {
        if (filter.visible && filter.visible !== survey_type) return null;
        return <SelectFilter key={`chart-filter-${filter.value}`} {...filter} />;
      })}
    </Box>
  );
};

export default ChartFiltersBar;
