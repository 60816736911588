import type { ComponentStyleConfig } from '@chakra-ui/react';

export const CloseButton: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    bg: 'transparency',
    _hover: {
      cursor: 'pointer',
      bg: 'primaryNeutral.100',
      _disabled: {
        bg: 'transparent',
      },
    },
    _active: {
      bg: 'primaryNeutral.600',
    },
  },
  variants: {
    light: {
      color: 'primary.100',
      _hover: {
        color: 'primary.400',
      },

      _focusVisible: {
        outlineColor: 'primary.100',
      },
    },
  },
};
