import { Button, Flex, FlexProps, FormControl, FormLabel, Heading, Input, Link, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../api';
import { useAuth } from '../hooks';
import routes from '../routes';
import AccountSetupModal from './AccountSetupModal';

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginDataValues {
  tc_accepted: boolean;
  reset_password: boolean;
}

const loginUser = async (values: LoginFormValues) => {
  const response = await api.post(routes.api.auth.login, values);
  if (response.status !== 200) {
    throw new Error('Error logging in');
  }

  return response.data as LoginDataValues;
};

const LoginForm: React.FC<FlexProps> = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { logout } = useAuth();
  const initialValues: LoginFormValues = { email: '', password: '' };
  const [loginData, setLoginData] = useState<LoginDataValues | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const mutation = useMutation(loginUser, {
    onSuccess: (data) => {
      setLoginData(data);
    },
    onError: () => {
      toast({
        title: 'Error logging in',
        description: 'Please check your credentials and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const validateLoginForm = (values: LoginFormValues) => {
    const errors: Partial<LoginFormValues> = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  const handleSubmit = (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    mutation.mutate(values);
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (loginData?.tc_accepted && !loginData.reset_password) {
      setOpenModal(false);
      return navigate(routes.app.org_select);
    }
    if (loginData !== null && (!loginData.tc_accepted || loginData.reset_password)) {
      setOpenModal(true);
    }
  }, [loginData, navigate]);

  return (
    <Flex pt={10} justifyContent="center" borderWidth={1} borderColor="gray.100">
      <Flex flexDirection="column" gap={10} width="50%">
        <Heading size="lg" fontWeight="normal">
          Login
        </Heading>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateLoginForm}>
          {({ errors }) => (
            <Form>
              <FormControl id="email" mt={4}>
                <FormLabel>Email</FormLabel>
                <Field
                  as={Input}
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  autoComplete="email"
                  isInvalid={errors.email ? true : false}
                />
              </FormControl>

              <FormControl id="password" mt={4}>
                <FormLabel>Password</FormLabel>
                <Field
                  as={Input}
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  autoComplete="current-password"
                  isInvalid={errors.password ? true : false}
                />
              </FormControl>

              <Button mt={10} type="submit" fontWeight="normal" fontSize="normal" size="lg" borderRadius={10}>
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <Link href="https://go.captivateiq.com/benchmarking-beta-program-signup/" mt={4}>
          Not registered? Click here to learn more
        </Link>
      </Flex>
      <AccountSetupModal
        onClose={() => {}}
        onError={() => {
          setOpenModal(false);
          return logout();
        }}
        onSuccess={() => navigate(routes.app.org_select)}
        onDecline={() => {
          setOpenModal(false);
          return logout();
        }}
        tcAccepted={Boolean(loginData?.tc_accepted)}
        isOpen={openModal}
      />
    </Flex>
  );
};

export default LoginForm;
