import { Icon, IconProps } from '@chakra-ui/react';

const QuotesIcon: React.FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="#0A2028"
      d="M57.748 50.906V29.37H45.573c-.178-3.297-.089-6.377.267-9.234.356-2.852 1.34-5.13 2.946-6.826 1.607-1.696 4.147-2.408 7.627-2.14l1.34-9.769c-6.96-1.34-12.511.045-16.659 4.148-4.147 4.103-6.223 10.262-6.223 18.467v26.891h22.882-.005ZM23.76 50.906V29.37H11.58c-.177-3.297-.088-6.377.268-9.234.356-2.852 1.34-5.13 2.946-6.826 1.606-1.696 4.147-2.408 7.627-2.14l1.34-9.769C16.8.06 11.25 1.445 7.102 5.548 2.955 9.65.88 15.81.88 24.015v26.891H23.76Z"
      opacity={0.5}
    />
  </Icon>
);

export default QuotesIcon;
