import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

interface NoDataChartProps extends FlexProps {
  title?: string;
  description?: string;
}

const RandomHeightBarPair: React.FC = () => (
  <Flex direction="row" justifyContent="space-evenly" height="100%" width="100%" alignItems="flex-end">
    <Box
      bottom={0}
      width="40%"
      minHeight="10%"
      height={`${Math.round(Math.random() * 100)}%`}
      backgroundColor="secondaryNeutral.300"
      borderRadius={4}
    ></Box>
    <Box
      bottom={0}
      width="40%"
      minHeight="10%"
      height={`${Math.round(Math.random() * 100)}%`}
      backgroundColor="secondaryNeutral.400"
      borderRadius={4}
    ></Box>
  </Flex>
);

const NoDataChart: React.FC<NoDataChartProps> = (props) => {
  const defaultTitle = 'No Data';
  const defaultDescription =
    "Not enough data in the filtered results to create this chart. We don't show smaller data sets to protect the anonymity of your data. Unselect some filters to show results.";

  return (
    <Flex
      width={600}
      height={300}
      backgroundColor="secondaryNeutral.200"
      p={6}
      borderRadius={4}
      direction="column"
      justifyContent="space-between"
      {...props}
    >
      <Text fontSize={20}>{props.title || defaultTitle}</Text>
      <Flex width="100%" height="100%" justifyContent="space-evenly" p={3}>
        <RandomHeightBarPair />
        <RandomHeightBarPair />
        <RandomHeightBarPair />
        <RandomHeightBarPair />
      </Flex>
      <Text id="no-data-chart-description" fontSize={14} color="primaryNeutral.900">
        {props.description || defaultDescription}
      </Text>
    </Flex>
  );
};

export default NoDataChart;
