import { Link } from '@chakra-ui/react';

const CAPrivacyLink = () => {
  return (
    <Link as="a" href="https://www.captivateiq.com/privacy-policy#california" color={'blue'} isExternal>
      CA Privacy Notice
    </Link>
  );
};

export default CAPrivacyLink;
