import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import QuartileBarChart from './QuartileBarChart';

type Quartiles = {
  total: number;
  min: number;
  max: number;
  quartiles: number[];
};

interface PY07Data {
  self: Quartiles;
  items: Quartiles;
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#7F3BFF': '#5F2D9F',
};

const PY07 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: response,
    error,
    isSuccess,
    isLoading,
  } = useChart<PY07Data | null>('charts/payee/py-07', 'py-07', org.id, chartFilters, survey?.id);
  let maxValue = 0;
  let chartData: BarDatum[] = [];

  if (isSuccess && response) {
    maxValue = Math.max(...response.items.quartiles, ...response.self.quartiles);
    const columns = ['25th Percentile', '50th Percentile', '75th Percentile', '90th Percentile'];
    chartData = columns.map((column, index) => ({
      All: response.items.quartiles[index],
      AllColor: '#DBD7D3',
      'My Company': response.self.quartiles[index],
      'My CompanyColor': '#7F3BFF',
      id: column,
    }));
  }

  return (
    <BaseChart
      title="What is the on-target earnings for plan participants?"
      subtitle="OTE"
      description="On-target earnings (OTE) is the total expected cash compensation for a plan participant when 100% of quota is earned (i.e., 100% of OTV is earned) + the base, or fixed, compensation. Total OTE varies significantly based on the roles, responsibilities and experience of plan participants."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && response) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && response ? (
        <QuartileBarChart
          data={chartData}
          maxValue={maxValue}
          barColors={barColors}
          height={400}
          width={600}
          label={(d) => `$${d.value}`}
          margin={{ top: 0, right: 0, bottom: 100, left: 0 }}
          keys={['All', 'My Company']}
          groupMode="grouped"
          labelSkipWidth={20}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              itemsSpacing: 100,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: 0,
              translateY: 100,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PY07;
