import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import { colors } from '../../theme/foundations/colors/colors';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import WaffleChart from './WaffleChart';

type WaffleDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

interface PY06Data {
  total: number;
  items: {
    [key: string]: number;
  };
  sample: number;
  self: {
    [key: string]: number;
  };
}

const PY06 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: count,
    error,
    isSuccess,
    isLoading,
  } = useChart<PY06Data>('charts/payee/py-06', 'py-06', org.id, chartFilters, survey?.id);
  const [waffleData, setWaffleData] = useState<WaffleDatum[]>([]);
  const [myData, setMyData] = useState<WaffleDatum[]>([]);

  useEffect(() => {
    if (isSuccess && count) {
      setWaffleData(
        Object.entries(count.items || [])
          .reverse()
          .map(([id, data], index) => {
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          }),
      );
      setMyData(
        Object.entries(count.self || [])
          .reverse()
          .map(([id, data], index) => {
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          }),
      );
    }
  }, [count, error, isSuccess]);

  return (
    <BaseChart
      title="What percentage of the sales team is attaining sales targets?"
      subtitle="Attainment breakpoints"
      description="An alternative view of the attainment percentages, this chart shows the overall attainment of plan participants broken out into incremental ranges of attainment."
      isLoading={isLoading && waffleData.length === 0}
      hiddenDefinition={!(isSuccess && count) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && count && waffleData.length > 0 ? (
        <Flex alignItems="center" direction="row">
          <Flex direction="column" justifyContent="center">
            <Box mr={10} p={0}>
              <Text fontSize="lg" fontWeight="bold" pl={110}>
                All Data
              </Text>
              <WaffleChart
                width={300}
                height={300}
                data={waffleData}
                motionStagger={2}
                legends={[
                  {
                    anchor: 'left',
                    direction: 'column',
                    itemHeight: 20,
                    itemWidth: 110,
                    itemsSpacing: 20,
                    symbolShape: 'circle',
                    symbolSize: 15,
                    translateX: -110,
                    translateY: 0,
                  },
                ]}
                margin={{ top: 0, right: 0, bottom: 0, left: 110 }}
                columns={10}
                rows={10}
              />
            </Box>
          </Flex>
          {count.sample > 0 && (
            <Flex direction="column" justifyContent="center">
              <Text fontSize="lg" fontWeight="bold">
                My Data
              </Text>
              <WaffleChart
                width={190}
                height={300}
                data={myData}
                motionStagger={2}
                legends={[]}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                columns={10}
                rows={10}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <NoDataChart />
      )}
    </BaseChart>
  );
};

export default PY06;
