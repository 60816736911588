import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import { colors } from '../../theme/foundations/colors/colors';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import WaffleChart from './WaffleChart';

type WaffleDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

interface OG07Data {
  total: number;
  items: {
    [key: string]: number;
  };
  self: string;
}

const OG07 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: count,
    error,
    isSuccess,
    isLoading,
  } = useChart<OG07Data>('charts/org/og-07', 'og-07', org.id, chartFilters, survey?.id);
  const [waffleData, setWaffleData] = useState<WaffleDatum[]>([]);

  useEffect(() => {
    if (isSuccess && count) {
      setWaffleData(
        Object.entries(count.items || [])
          .map(([id, data], index) => {
            id = id.replace(' (mine)', '');
            if (id === count.self) id = `${id} (mine)`;
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          })
          .sort((a, b) => b.value - a.value),
      );
    }
  }, [count, error, isSuccess]);

  return (
    <BaseChart
      title="How fast are compensation calculations finalized?"
      subtitle="Days to close"
      description="Days to close is another measure of process efficiency. Common areas that add days to the process are days to clean data prior to calculations, multiple rounds of payout reviews, etc."
      isLoading={isLoading && waffleData.length === 0}
      hiddenDefinition={!(isSuccess && count) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && count && waffleData.length > 0 ? (
        <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
          <WaffleChart
            width={600}
            height={300}
            data={waffleData}
            motionStagger={2}
            legends={[
              {
                anchor: 'left',
                direction: 'column',
                itemHeight: 20,
                itemWidth: 265,
                itemsSpacing: 20,
                symbolShape: 'circle',
                symbolSize: 18,
                translateX: -265,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 265 }}
          />
        </Flex>
      ) : (
        <NoDataChart />
      )}
    </BaseChart>
  );
};

export default OG07;
