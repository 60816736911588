import { Box, Grid } from '@chakra-ui/react';

import FeedbackComment from '../components/FeedbackComment';
import LoginForm from '../components/LoginForm';
import LoginCornerIcon from '../icons/LoginCornerIcon';

const LoginPage: React.FC = () => {
  return (
    <Box position="relative">
      <Grid h="95vh" w="100%" templateColumns="1fr 1fr" templateRows="1fr">
        <LoginForm />
        <FeedbackComment />
      </Grid>
      <LoginCornerIcon position="absolute" right={0} bottom={0} width="346" height="158" viewBox="0 0 346 158" />
    </Box>
  );
};

export default LoginPage;
