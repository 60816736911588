import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import WaffleChart from './WaffleChart';

interface OG09Data {
  total: number;
  items: {
    [key: string]: number;
  };
  self: string;
}

const data = [
  {
    color: '#2D7B81',
    id: 'No plans modified',
    label: 'No plans modified',
    value: 0,
  },
  {
    color: '#A1D240',
    id: 'Less than 25% of plans modified',
    label: 'Less than 25%',
    value: 0,
  },
  {
    color: '#7F3BFF',
    id: 'Between 25% and 50% of plans modified',
    label: 'Between 25% and 50%',
    value: 0,
  },
  {
    color: '#408FDB',
    id: 'Between 50% and 75% of plans modified',
    label: 'Between 50% and 75%',
    value: 0,
  },
  {
    color: '#FA8E55',
    id: 'More than 75% of plans modified',
    label: 'More than 75%',
    value: 0,
  },
];

const OG09 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: count,
    error,
    isSuccess,
    isLoading,
  } = useChart<OG09Data>('charts/org/og-09', 'og-09', org.id, chartFilters, survey?.id);
  let waffleData = data;

  if (isSuccess && count) {
    waffleData = data
      .map((item) => {
        item.label = item.label.replace(' (mine)', '');
        if (item.id === count.self) {
          item.label = `${item.label} (mine)`;
        }
        item.value = count.items[item.id];
        return item;
      })
      .sort((a, b) => b.value - a.value);
  }

  return (
    <BaseChart
      title="How common are unplanned plan modifications during the year?"
      subtitle="Modification frequency"
      description="Some companies plan to change their plans over the course of the year. For example, a role where the quota is defined only one quarter at a time and is updated over the course of the year. This question is focusing instead on unplanned changes to plan. These modifications are seen as needed to fix misalignment in quotas, roles & expecations, team changes, etc."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && count) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && count ? (
        <Flex justifyContent="center" alignItems="center" flexWrap="wrap" width={600} height={300}>
          <WaffleChart
            width={600}
            height={300}
            data={waffleData}
            motionStagger={2}
            legends={[
              {
                anchor: 'left',
                direction: 'column',
                itemHeight: 20,
                itemWidth: 180,
                itemsSpacing: 20,
                symbolShape: 'circle',
                symbolSize: 18,
                translateX: -180,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 180 }}
          />
        </Flex>
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default OG09;
