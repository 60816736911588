import { Flex, FlexProps, Text } from '@chakra-ui/react';

import CAPrivacyLink from './CAPrivacyLink';
import PrivacyLink from './PrivacyLink';
import TermsLink from './TermsLink';

const LegalFooter: React.FC<FlexProps> = (props) => {
  return (
    <Flex direction="column" justifyContent="center" alignItems="center" {...props}>
      <Text as="span">
        © CaptivateIQ, Inc. All rights reserved. | {<TermsLink />} | {<PrivacyLink />} | {<CAPrivacyLink />}
      </Text>
      <Text>
        All information on this site is for informational purposes only and does not constitute business or financial
        advice.
      </Text>
    </Flex>
  );
};

export default LegalFooter;
