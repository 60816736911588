import { Flex } from '@chakra-ui/react';

import OrgList from '../components/OrgList';

const OrgSelectPage = () => {
  return (
    <Flex direction="column" width="100%" alignItems="center">
      <OrgList width="50%" />
    </Flex>
  );
};

export default OrgSelectPage;
