import { Box, BoxProps, Button, Flex, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Organization } from '@/types';
import { useAuth, useUser } from '../hooks';
import routes from '../routes';

const OrgList = (props: BoxProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [org, setOrg] = useState<Organization | null>(null);
  const { logout } = useAuth();
  const { data: user, error, isSuccess } = useUser();

  useEffect(() => {
    if (org) {
      localStorage.setItem('org', JSON.stringify(org));
      navigate(routes.app.base.url);
    }
    if (error) logout();
    if (isSuccess) {
      if (user.organizations.length === 0) {
        toast({
          title: 'No organizations found',
          description: 'Contact system administrator.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        logout();
      }
      if (user.organizations.length === 1) {
        setOrg(user.organizations[0]);
      }
    }
    return () => setOrg(null);
  }, [navigate, org, user, error, isSuccess, logout, toast]);

  if (!isSuccess) return <Heading>Loading...</Heading>;

  return (
    <Box {...props}>
      {user.organizations.map((org) => (
        <Flex bgColor="gray.100" width="100%" my={5} p={10} borderRadius={10} key={`${org.id}-${Date.now()}`}>
          <Flex justifyContent="space-between" width="100%">
            <Heading size="md" fontWeight="normal">
              {org.name}
            </Heading>
            <Button fontWeight="normal" onClick={() => setOrg(org)}>
              Continue
            </Button>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
};

export default OrgList;
