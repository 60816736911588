import { Box, Grid, GridProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import AppSidebar from '../components/AppSidebar';
import LegalFooter from '../components/LegalFooter';
import { useAuth } from '../hooks/Auth';
import { useUser } from '../hooks/User';

export default function AppLayout(props: GridProps) {
  const { logout } = useAuth();
  const { data, error, isSuccess } = useUser();

  useEffect(() => {
    const signout = async () => await logout();
    if (error) signout();
  }, [data, error, logout]);

  if (!isSuccess) return null;

  return (
    <Grid h="100vh" w="100vw" templateColumns="1fr 5fr" templateRows="1fr" {...props}>
      <Box as="div">
        <AppSidebar position={'sticky'} top={0} zIndex={1000} />
      </Box>
      <Box as="div">
        <Box as="div" minHeight="100%">
          <Box as="main" overflow="auto" pb="100px">
            <Outlet />
          </Box>
        </Box>
        <LegalFooter
          as="footer"
          height="100px"
          mt="-100px"
          position={'relative'}
          backgroundColor="white"
          style={{ clear: 'both' }}
        />
      </Box>
    </Grid>
  );
}
