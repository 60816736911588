import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import QuartileBarChart from './QuartileBarChart';

type Quartiles = {
  total: number;
  quartiles: number[];
};

interface PL03Data {
  self: Quartiles;
  items: Quartiles;
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#7F3BFF': '#5F2D9F',
};

const PL03 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: response,
    error,
    isSuccess,
    isLoading,
  } = useChart<PL03Data>('charts/plan/pl-03', 'pl-03', org.id, chartFilters, survey?.id);
  let maxValue = 0;
  let chartData: BarDatum[] = [];

  if (isSuccess && response) {
    const columns = ['25th Percentile', '50th Percentile', '75th Percentile', '90th Percentile'];
    chartData = columns.map((column, index) => ({
      All: response.items.quartiles[index],
      AllColor: '#DBD7D3',
      'My Company': response.self.quartiles[index],
      'My CompanyColor': '#7F3BFF',
      id: column,
    }));
    maxValue = Math.max(...response.items.quartiles, ...response.self.quartiles);
  }

  return (
    <BaseChart
      title="How many quotas or targets are the sales team measuring within plans?"
      subtitle="Quotas per plan"
      description="Multiple targets are a helpful way to focus the sales team on the multiple items that are important to the company's overall performance. However, too many quotas can serve to distract teams, and complicate plan calculations."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && response) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && response ? (
        <QuartileBarChart
          data={chartData}
          maxValue={maxValue}
          barColors={barColors}
          height={400}
          width={600}
          margin={{ top: 0, right: 0, bottom: 100, left: 0 }}
          keys={['All', 'My Company']}
          groupMode="grouped"
          labelSkipWidth={20}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              itemsSpacing: 100,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: 0,
              translateY: 100,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PL03;
