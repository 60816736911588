import { Text, TextProps } from '@chakra-ui/react';

interface FormErrorMessageTextProps extends TextProps {
  children: string;
}

const FormErrorMessageText: React.FC<FormErrorMessageTextProps> = (props) => (
  <Text flex={1} justifyContent="flex-start" color="red.500" fontSize="sm" fontWeight="light" {...props}>
    {props.children}
  </Text>
);

export default FormErrorMessageText;
