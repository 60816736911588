import { Flex } from '@chakra-ui/react';

import HomeContent from '../components/HomeContent';
import HomeProfile from '../components/HomeProfile';

const HomePage = () => {
  return (
    <Flex direction="column" width="100%" height="100%" alignItems="center" pt={20}>
      <HomeProfile />
      <HomeContent py={10} />
    </Flex>
  );
};

export default HomePage;
