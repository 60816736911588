import { Route, Routes } from 'react-router-dom';

import AppLayout from './layouts/AppLayout';
import RootLayout from './layouts/RootLayout';
import ChartListPage from './pages/ChartListPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import OrgSelectPage from './pages/OrgSelectPage';
import SelfServicePage from './pages/SelfServicePage';
import SetPasswordPage from './pages/SetPasswordPage';
import SurveyListPage from './pages/SurveyListPage';
import routes from './routes';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={routes.app.marketing} element={<RootLayout />}>
          <Route index element={<LoginPage />} />
          <Route path={routes.app.login} element={<LoginPage />} />
          <Route path={routes.app.org_select} element={<OrgSelectPage />} />
          <Route path={routes.app.set_password} element={<SetPasswordPage />} />
          <Route path={routes.app.self_service} element={<SelfServicePage />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Route>
        <Route path={routes.app.base.url} element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route
            path={routes.app.base.surveys.organization}
            element={<SurveyListPage title="Organization" survey_type="org" />}
          />
          <Route
            path={routes.app.base.surveys.plan_design}
            element={<SurveyListPage title="Plan Design" survey_type="plan" />}
          />
          <Route
            path={routes.app.base.surveys.performance}
            element={<SurveyListPage title="Performance" survey_type="payee" />}
          />
          <Route path={routes.app.base.charts.base} element={<ChartListPage />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
