import axios from 'axios';

import routes from './routes';

const api = axios.create({
  baseURL: routes.api.base.url,
  withCredentials: true,
});

export default api;
