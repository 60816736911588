import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import api from '../api';
import routes from '../routes';

export function useAuth() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = async () => {
    try {
      await api.get(routes.api.auth.logout);
    } finally {
      localStorage.clear();
      queryClient.clear();
      return navigate(routes.app.login);
    }
  };

  return { logout };
}
