import { Box, Button, Flex, FlexProps, FormControl, FormLabel, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import api from '../api';
import FormErrorMessageText from '../components/FormErrorMessageText';
import { useUser } from '../hooks';
import routes from '../routes';

interface SetPasswordValues {
  old_password: string;
  new_password: string;
  re_password?: string;
}

interface SetPasswordRequest {
  values: SetPasswordValues;
  csrftoken: string;
}

const initialValues: SetPasswordValues = {
  old_password: '',
  new_password: '',
  re_password: '',
};

const validateForm = (values: SetPasswordValues) => {
  const errors: Partial<SetPasswordValues> = {};

  if (!values.old_password) errors.old_password = 'Required';
  if (!values.new_password) errors.new_password = 'Required';
  if (!values.re_password) errors.re_password = 'Required';
  if (values.new_password !== values.re_password) {
    errors.re_password = 'Passwords do not match';
  }
  if (values.new_password === values.old_password) {
    errors.new_password = 'New password cannot be the same as old password';
  }

  return errors;
};

const setPassword = async ({ values, csrftoken }: SetPasswordRequest) => {
  const response = await api.post(routes.api.auth.password, values, {
    headers: {
      'X-Csrftoken': csrftoken,
    },
  });

  if (response.status !== 200) {
    throw new Error('Invalid request');
  }

  return response.data;
};

const SetPasswordForm: React.FC<FlexProps> = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isSuccess } = useUser();
  const [{ csrftoken }] = useCookies(['csrftoken']);

  const mutation = useMutation(setPassword, {
    onSuccess: () => {
      return navigate(-1);
    },
    onError: () => {
      toast({
        title: 'Error Setting Password',
        description: 'Please check your credentials and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = (values: SetPasswordValues, actions: FormikHelpers<SetPasswordValues>) => {
    mutation.mutate({ values, csrftoken });
    actions.setSubmitting(false);
  };

  return (
    <Flex flexDirection="column" gap={10} {...props}>
      {isSuccess ? (
        <>
          <Box onClick={() => navigate(-1)} _hover={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={icon({ name: 'arrow-left' })} />
            <Heading size="sm" fontWeight="medium" as="span" px={3}>
              Back
            </Heading>
          </Box>
          <Heading size="lg" fontWeight="normal">
            Set a password
          </Heading>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
            {({ errors }) => (
              <Form>
                <FormControl id="old_password" mt={4}>
                  <FormLabel>Current Password</FormLabel>
                  <Field
                    as={Input}
                    name="old_password"
                    type="password"
                    placeholder="Enter your current password"
                    isInvalid={errors.old_password ? true : false}
                  />
                  <ErrorMessage
                    name="old_password"
                    component={Text}
                    render={(msg: string) => <FormErrorMessageText children={msg} />}
                  />
                </FormControl>

                <FormControl id="new_password" mt={4}>
                  <FormLabel>New Password</FormLabel>
                  <Field
                    as={Input}
                    name="new_password"
                    type="password"
                    placeholder="Enter your new password"
                    isInvalid={errors.new_password ? true : false}
                  />
                  <ErrorMessage
                    name="new_password"
                    component={Text}
                    render={(msg: string) => <FormErrorMessageText children={msg} />}
                  />
                </FormControl>

                <FormControl id="re_password" mt={4}>
                  <FormLabel>Re-Enter New Password</FormLabel>
                  <Field
                    as={Input}
                    name="re_password"
                    type="password"
                    placeholder="Enter your new password again"
                    isInvalid={errors.re_password ? true : false}
                  />
                  <ErrorMessage
                    name="re_password"
                    component={Text}
                    render={(msg: string) => <FormErrorMessageText children={msg} />}
                  />
                </FormControl>

                <Button mt={10} type="submit" fontWeight="normal" fontSize="normal" size="lg" borderRadius={10}>
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Heading as="h1" size="lg" fontWeight="normal">
          Loading...
        </Heading>
      )}
    </Flex>
  );
};

export default SetPasswordForm;
