import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Badge,
  Box,
  BoxProps,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Organization } from '@/types';
import { useAuth, useStickyState, useUser } from '../hooks/';
import routes from '../routes';

const AppSidebar: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>('');
  const { logout } = useAuth();
  const { data: user } = useUser();
  const [org] = useStickyState<Organization>(null, 'org');

  useEffect(() => {
    if (url) navigate(url);
    return () => setUrl('');
  }, [navigate, url]);

  return (
    <Grid
      borderRightWidth={1}
      bgColor="gray.100"
      height="100vh"
      width="100%"
      templateColumns="1fr"
      templateRows="2fr 20fr 2fr"
      {...props}
    >
      <GridItem cursor="default" userSelect="none">
        <Flex direction="column" justifyContent="space-between" width="100%" color="primary.600" px={4} py={5}>
          <Heading as="h1" size="lg" py={1}>
            CaptivateIQ
          </Heading>
          <Text fontWeight="normal" fontSize={'lg'} py={1}>
            Benchmarking
            <Badge as="span" colorScheme="blue" p={1} mx={3}>
              Beta
            </Badge>
          </Text>
        </Flex>
      </GridItem>
      <GridItem>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem border="none">
            <Heading as="h2">
              <AccordionButton>
                <FontAwesomeIcon icon={icon({ name: 'house' })} />
                <Box as="span" flex="1" textAlign="left" ml={3} onClick={() => setUrl(routes.app.base.url)}>
                  Home
                </Box>
              </AccordionButton>
            </Heading>
          </AccordionItem>
          <AccordionItem border="none">
            <Heading as="h2">
              <AccordionButton>
                <FontAwesomeIcon icon={icon({ name: 'envelope-open-text' })} />
                <Box as="span" flex="1" textAlign="left" ml={3}>
                  Benchmarks
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel px={7}>
              <AccordionButton
                onClick={(e) => {
                  e.preventDefault();
                  setUrl(routes.app.base.surveys.organization);
                }}
              >
                Organization
              </AccordionButton>
              <AccordionButton
                onClick={(e) => {
                  e.preventDefault();
                  setUrl(routes.app.base.surveys.plan_design);
                }}
              >
                Plan Design
              </AccordionButton>
              <AccordionButton
                onClick={(e) => {
                  e.preventDefault();
                  setUrl(routes.app.base.surveys.performance);
                }}
              >
                Performance
              </AccordionButton>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem border="none">
            <Heading as="h2">
              <AccordionButton as="a" href="https://app.captivateiq.com/">
                <FontAwesomeIcon
                  icon={icon({ name: 'calculator' })}
                  style={{ paddingLeft: '2px', paddingRight: '2px' }}
                />
                <Text as="span" flex="1" textAlign="left" pl={3} fontWeight={'normal'}>
                  CaptivateIQ
                </Text>
                <FontAwesomeIcon
                  icon={icon({ name: 'up-right-from-square' })}
                  style={{ paddingLeft: '2px', paddingRight: '2px' }}
                />
              </AccordionButton>
            </Heading>
          </AccordionItem>
        </Accordion>
      </GridItem>
      <GridItem flex={1} alignSelf={'center'} width={'100%'} borderTopWidth={1} py={5}>
        <HStack flex="1" gap="0" alignItems="center" flexWrap="wrap" justifyItems={'center'} justifyContent={'center'}>
          <Avatar name={`${user?.first_name} ${user?.last_name}`} src="" size={'sm'} mx={3} />
          <VStack flex={'1'} alignItems={'flex-start'} gap={0} justifyItems={'center'}>
            <Heading size="xs" textAlign={'center'}>
              {`${user?.first_name} ${user?.last_name}`}
            </Heading>
            <Text fontWeight="light" fontSize={12}>
              {org?.name}
            </Text>
          </VStack>
          <Menu>
            <MenuButton>
              <Box mx={3}>
                <FontAwesomeIcon icon={icon({ name: 'ellipsis-vertical' })} />
              </Box>
            </MenuButton>
            <MenuList>
              <MenuItem>
                <VStack flex={'1'} alignItems={'flex-start'} gap={0} justifyItems={'center'}>
                  <Heading size="xs" textAlign={'center'}>
                    {`${user?.first_name} ${user?.last_name}`}
                  </Heading>
                  <Text fontWeight="light" fontSize={12}>
                    {org?.name}
                  </Text>
                </VStack>
              </MenuItem>
              <MenuItem
                as="a"
                href="https://www.captivateiq.com/privacy-policy"
                flex={1}
                justifyContent={'space-between'}
              >
                Privacy Policy
                <Box as="span" mx={3}>
                  <FontAwesomeIcon icon={icon({ name: 'up-right-from-square' })} />
                </Box>
              </MenuItem>
              <MenuItem
                as="a"
                href="https://go.captivateiq.com/benchmarking-program-terms-and-conditions"
                flex={1}
                justifyContent={'space-between'}
              >
                Program Terms
                <Box as="span" mx={3}>
                  <FontAwesomeIcon icon={icon({ name: 'up-right-from-square' })} />
                </Box>
              </MenuItem>
              {user?.is_staff ? (
                <MenuItem as="a" href={routes.app.self_service}>
                  Self Service
                </MenuItem>
              ) : null}
              {user?.organizations?.length !== 1 ? (
                <>
                  <Divider my={2} />
                  <MenuItem as="a" href={routes.app.org_select}>
                    Switch organizations
                  </MenuItem>
                </>
              ) : null}
              <Divider my={2} />
              <MenuItem as="a" href={routes.app.set_password}>
                Change password
              </MenuItem>
              <Divider my={2} />
              <MenuItem as="button" onClick={() => logout()}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </GridItem>
    </Grid>
  );
};

export default AppSidebar;
