import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import QuartileBarChart from './QuartileBarChart';

type Quartiles = {
  total: number;
  min: number;
  max: number;
  quartiles: number[];
};

interface PY08Data {
  self: {
    ic_ratio: number;
    pc_ratio: number;
    total: number;
  };
  items: {
    ic_ratios: Quartiles;
    pc_ratios: Quartiles;
  };
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#408FDB': '#207FCB',
};

const PY08 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: response,
    error,
    isSuccess,
    isLoading,
  } = useChart<PY08Data | null>('charts/payee/py-08', 'py-08', org.id, chartFilters, survey?.id);
  let maxValue = 0;
  let chartData: BarDatum[] = [];

  if (isSuccess && response) {
    const columns = ['25th Percentile', '50th Percentile', '75th Percentile', '90th Percentile'];
    chartData = columns.map((id, index) => {
      const pc_ratio = response.items.pc_ratios.quartiles[index];
      const ic_ratio = response.items.ic_ratios.quartiles[index];
      maxValue = Math.max(maxValue, pc_ratio + ic_ratio);
      return {
        'Player Coach': pc_ratio,
        'Player CoachColor': '#408FDB',
        'Individual Contributor': ic_ratio,
        'Individual ContributorColor': '#DBD7D3',
        id,
      };
    });
    maxValue = Math.max(maxValue, response.self.pc_ratio + response.self.ic_ratio);
    chartData.push({
      'Player Coach': response.self.pc_ratio,
      'Player CoachColor': '#408FDB',
      'Individual Contributor': response.self.ic_ratio,
      'Individual ContributorColor': '#DBD7D3',
      id: 'My Company',
    });
  }

  return (
    <BaseChart
      title="How much headcount is there per manager?"
      subtitle="HC per manager"
      description="A larger ratio indicates a larger number of total sales team members per individuals in this role. For example, a manager ratio of 6 means that there is 1 manager for every 6 people on the sales team."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && response) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && response ? (
        <QuartileBarChart
          data={chartData}
          maxValue={maxValue}
          barColors={barColors}
          height={400}
          width={600}
          label={(d) => `${d.value}`}
          margin={{ top: 0, right: 0, bottom: 100, left: 0 }}
          keys={['Player Coach', 'Individual Contributor']}
          groupMode="stacked"
          labelSkipWidth={20}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              itemsSpacing: 100,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: 0,
              translateY: 100,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PY08;
