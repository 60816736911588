import { Survey } from './types';

export const toSurveyTitle = (survey: Survey, org_name: string) => {
  const { survey_type, effective_at, survey_period } = survey;
  const [year, month, day] = effective_at.split('-');
  const cadence = survey_period[0] + survey_period.slice(1).toLowerCase();
  let survey_name = '';

  switch (survey_type) {
    case 'org':
      survey_name = 'Organization';
      break;
    case 'plan':
      survey_name = 'Plan Design';
      break;
    case 'payee':
      survey_name = 'Performance';
      break;
  }

  return `${org_name} | ${survey_name} | ${[month, day, year].join('-')} | ${cadence} Year`;
};
