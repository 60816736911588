import { QueryKey, useQuery } from '@tanstack/react-query';

import { Survey } from '@/types';
import api from '../api';
import routes from '../routes';

async function fetchSurveys({ queryKey }: { queryKey: QueryKey }) {
  const [, survey_type, orgId] = queryKey as [string, string, number];
  const response = await api.get(routes.api.surveys.survey_type.by_org_id(survey_type, orgId));
  if (response.status !== 200) {
    throw new Error(`Error fetching data`);
  }

  return response.data;
}

export function useSurvey(survey_type: string | null, org_id: number) {
  return useQuery<Survey[], unknown>(['surveys', survey_type, org_id], fetchSurveys, {
    enabled: !!survey_type && !!org_id,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
}

async function fetchAllSurveys({ queryKey }: { queryKey: QueryKey }) {
  const [, orgId] = queryKey as [string, number];
  const response = await api.get(routes.api.surveys.all.by_org_id(orgId));
  if (response.status !== 200) {
    throw new Error(`Error fetching data`);
  }

  return response.data;
}

export function useAllSurveys(org_id: number) {
  return useQuery<Survey[], unknown>(['surveys', org_id], fetchAllSurveys, {
    enabled: !!org_id,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
}
