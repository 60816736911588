import { Box, Text } from '@chakra-ui/react';
import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import TransposedBarChart from './TransposedBarChart';

interface OG10Data {
  total: number;
  sample: number;
  self: {
    [key: string]: number;
  };
  items: {
    [key: string]: number;
  };
}

const barColors = {
  '#E1FFB4': '#B8D080',
  '#A1D240': '#7F9C3B',
};

const OG10 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: modified,
    error,
    isSuccess,
    isLoading,
  } = useChart<OG10Data | null>('charts/org/og-10', 'og-10', org.id, chartFilters, survey?.id);
  let data = [] as BarDatum[];
  let maxValue = 0;

  if (isSuccess && modified) {
    data = Object.entries(modified?.items || [])
      .map(([id, data]) => ({
        id,
        Component: Math.round((data / modified.sample) * 100),
        ComponentColor: modified.self[id] ? '#A1D240' : '#E1FFB4',
      }))
      .sort((a, b) => a.Component - b.Component);
    maxValue = Math.max(...data.map((d) => d.Component as number));
  }

  return (
    <BaseChart
      title="What components of plans are modified when there is an unplanned modification?"
      subtitle="Modified components"
      description="Modifications to some components, like quotas and tiers, tend to be friendly to plan participants. Other changes, like payment rules, are more frequently made to protect the company."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && modified) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && modified ? (
        <>
          <TransposedBarChart
            barColors={barColors}
            data={data}
            height={600}
            width={600}
            maxValue={maxValue}
            label={(d) => `${d.value}%`}
            margin={{ top: 0, right: 0, bottom: 0, left: 250 }}
            keys={['Component']}
            legends={[]}
          />
          <Box display="flex" justifyContent="flex-start" width="100%" fontWeight="medium" fontSize={12}>
            <Text display="flex" alignItems="center" mr={3}>
              <Box as="span" width={5} height={5} borderRadius={10} backgroundColor="#E1FFB4" mr={2}></Box>
              All, but I didn't select
            </Text>
            <Text display="flex" alignItems="center" mr={3}>
              <Box as="span" width={5} height={5} borderRadius={10} backgroundColor="#A1D240" mr={2}></Box>
              All + Ones I selected
            </Text>
          </Box>
        </>
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default OG10;
