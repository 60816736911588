import { Link } from '@chakra-ui/react';

const TermsLink = () => {
  return (
    <Link as="a" href="https://go.captivateiq.com/benchmarking-program-terms-and-conditions" color={'blue'} isExternal>
      Benchmarking Beta Terms
    </Link>
  );
};

export default TermsLink;
