import { Bar, BarDatum, BarSvgProps } from '@nivo/bar';

interface QuartileBarChartProps extends BarSvgProps<BarDatum> {
  barColors: BarDatum;
}

const QuartileBarChart: React.FC<QuartileBarChartProps> = (props) => {
  return (
    <Bar
      indexBy={'id'}
      borderRadius={4}
      labelSkipHeight={1}
      enableGridY={false}
      enableGridX={false}
      margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
      labelTextColor={{
        from: 'color',
        modifiers: [['brighter', 100]],
      }}
      // @ts-ignore
      markers={props.markers}
      // @ts-ignore
      colors={({ id, data }) => data[`${id}Color`]}
      onMouseEnter={({ id, data }, event) => {
        // @ts-ignore
        event.target.style.fill = props.barColors[data[`${id}Color`]];
      }}
      onMouseLeave={({ id, data }, event) => {
        // @ts-ignore
        event.target.style.fill = data[`${id}Color`];
      }}
      padding={0.25}
      innerPadding={0.5}
      {...props}
    />
  );
};

export default QuartileBarChart;
