import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        bg: 'primaryNeutral.50',
        borderRadius: '30px',
        border: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
