import { BarDatum } from '@nivo/bar';
import { CartesianMarkerProps, DatumValue } from '@nivo/core';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import QuartileBarChart from './QuartileBarChart';

type Quartiles = {
  total: number;
  min: number;
  max: number;
  quartiles: number[];
};

interface OG06Data {
  items: Quartiles;
  markers: number[];
}

const barColors = {
  '#7F3BFF': '#5C2AB8',
};

const OG06 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: percentiles,
    error,
    isSuccess,
    isLoading,
  } = useChart<OG06Data | null>('charts/org/og-06', 'og-06', org.id, chartFilters, survey?.id);

  let data = [] as BarDatum[];
  let markers = [] as CartesianMarkerProps<DatumValue>[];
  let maxValue = 0;

  if (isSuccess && percentiles) {
    const columns = ['25th Percentile', '50th Percentile', '75th Percentile', '90th Percentile'];
    maxValue = Math.max(maxValue, ...percentiles.markers);
    data = columns.map((column, index) => {
      const all = Math.round(percentiles.items.quartiles[index]);
      maxValue = Math.max(maxValue, all);
      return {
        id: column,
        Ratio: all,
        RatioColor: '#7F3BFF',
      };
    });

    markers = percentiles?.markers
      .map((mark) => [
        {
          axis: 'y',
          value: mark,
          legend: 'My company',
          legendPosition: 'top-left',
          lineStyle: {
            stroke: 'rgba(0, 0, 0, .25)',
            strokeWidth: 3,
            paddingTop: 100,
          },
          textStyle: { fontSize: 12, fill: 'rgba(0, 0, 0, .75)' },
        },
        {
          axis: 'y',
          value: mark,
          legend: `${Math.round(mark)}`,
          legendPosition: 'bottom-left',
          lineStyle: { stroke: 'rgba(0, 0, 0, .25)', strokeWidth: 3 },
          textStyle: { fontSize: 24, fill: 'rgba(0, 0, 0, .85)' },
        },
      ])
      .reduce((acc, curr) => acc.concat(curr), []) as CartesianMarkerProps<DatumValue>[];
  }

  return (
    <BaseChart
      title="How many salespeople do you have per admin calculating sales compensation?"
      subtitle="Payees / Admins"
      description="Comparing the size of the sales team to the number of people that calculate sales compensation is a measure of the efficiency of the sales org. However, this number can be less accurate a signal when the people calculating sales commissions are responsible for things other than sales compensation."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && percentiles) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && percentiles ? (
        <QuartileBarChart
          data={data}
          maxValue={maxValue}
          barColors={barColors}
          height={300}
          width={600}
          markers={markers}
          keys={['Ratio']}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
          }}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default OG06;
