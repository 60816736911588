import React from 'react';

import { useStickyState } from '../hooks';
import { ChartFiltersType } from '../types';

interface ChartFiltersStateContextType {
  chartFilters: ChartFiltersType;
  setChartFilters: React.Dispatch<React.SetStateAction<ChartFiltersType>>;
}

const ChartFiltersStateContext = React.createContext<ChartFiltersStateContextType | undefined>(undefined);

interface ChartFiltersStateProviderProps {
  children: React.ReactNode;
}

export const ChartFiltersStateProvider: React.FC<ChartFiltersStateProviderProps> = ({ children }) => {
  const [chartFilters, setChartFilters] = useStickyState<ChartFiltersType>({}, 'chart_filters');
  return (
    <ChartFiltersStateContext.Provider value={{ chartFilters, setChartFilters }}>
      {children}
    </ChartFiltersStateContext.Provider>
  );
};

export function useChartFilters(): ChartFiltersStateContextType {
  const context = React.useContext(ChartFiltersStateContext);
  if (context === undefined) {
    throw new Error('useChartFilters must be used within a ChartFiltersStateProvider');
  }
  return context;
}
