import { Bar, BarDatum, BarSvgProps } from '@nivo/bar';

interface TransposedBarChartProps extends BarSvgProps<BarDatum> {
  barColors: BarDatum;
}

const TransposedBarChart: React.FC<TransposedBarChartProps> = (props) => {
  return (
    <Bar
      indexBy={'id'}
      keys={['data']}
      borderRadius={4}
      enableGridY={false}
      enableGridX={false}
      layout="horizontal"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 0,
      }}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 50]],
      }}
      // @ts-ignore
      markers={props.markers}
      // @ts-ignore
      colors={({ id, data }) => data[`${id}Color`]}
      onMouseEnter={({ id, data }, event) => {
        // @ts-ignore
        event.target.style.fill = props.barColors[data[`${id}Color`]];
      }}
      onMouseLeave={({ id, data }, event) => {
        // @ts-ignore
        event.target.style.fill = data[`${id}Color`];
      }}
      padding={0.25}
      innerPadding={0.5}
      {...props}
    />
  );
};

export default TransposedBarChart;
