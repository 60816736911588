import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useCookies } from 'react-cookie';

import api from '../api';
import routes from '../routes';
import FormErrorMessageText from './FormErrorMessageText';
import PrivacyLink from './PrivacyLink';
import TermsLink from './TermsLink';

interface TCModalProps extends Partial<ModalProps> {
  isOpen: boolean;
  tcAccepted: boolean;
  onClose(): void;
  onDecline(): void;
  onSuccess(): void;
  onError(): void;
  onAccept?(): void;
}

interface SetPasswordValues {
  new_password: string;
  re_password?: string;
  terms_and_conditions?: boolean | string;
}

interface SetPasswordRequest {
  values: SetPasswordValues;
  csrftoken: string;
}

interface SetPasswordFormProps {
  errors: Partial<SetPasswordValues>;
  tcAccepted: boolean;
}

const initialValues: SetPasswordValues = {
  new_password: '',
  re_password: '',
  terms_and_conditions: false,
};

const validateForm = (values: SetPasswordValues) => {
  const errors: Partial<SetPasswordValues> = {};

  if (!values.new_password) errors.new_password = 'Required';
  if (!values.re_password) errors.re_password = 'Required';
  if (values.new_password !== values.re_password) errors.re_password = 'Passwords do not match';

  return errors;
};

const resetPassword = async ({ values, csrftoken }: SetPasswordRequest) => {
  const response = await api.post(routes.api.auth.reset_password, values, {
    headers: { 'X-Csrftoken': csrftoken },
  });

  if (response.status !== 200) {
    throw new Error('Invalid request');
  }

  return response.data;
};

const SetPasswordForm = ({ errors, tcAccepted }: SetPasswordFormProps) => {
  return (
    <>
      <Text my={3}>Create new password</Text>
      <FormControl id="new_password" mt={4}>
        <FormLabel>New password*</FormLabel>
        <Field
          as={Input}
          name="new_password"
          type="password"
          placeholder="Enter your new password"
          autoComplete="new-password"
          isInvalid={errors.new_password ? true : false}
          isRequired
        />
        <ErrorMessage
          name="new_password"
          component={Text}
          render={(msg: string) => <FormErrorMessageText children={msg} />}
        />
      </FormControl>

      <FormControl id="re_password" mt={4}>
        <FormLabel>Re-enter new password*</FormLabel>
        <Field
          as={Input}
          name="re_password"
          type="password"
          placeholder="Enter your new password again"
          autoComplete="new-password"
          isInvalid={errors.re_password ? true : false}
          isRequired
        />
        <ErrorMessage
          name="re_password"
          component={Text}
          render={(msg: string) => <FormErrorMessageText children={msg} />}
        />
      </FormControl>

      <Box mt={5}>
        <Text my={3}>Terms and conditions</Text>
        <FormControl id="terms_and_conditions" mt={4}>
          <Field
            as={Checkbox}
            name="terms_and_conditions"
            isInvalid={errors.terms_and_conditions ? true : false}
            defaultChecked={tcAccepted}
            isRequired
          >
            <Text as="span">
              I agree to CaptivateIQ's {<TermsLink />} and acknowledge its {<PrivacyLink />}.*
            </Text>
          </Field>
          <ErrorMessage
            name="terms_and_conditions"
            component={Text}
            render={(msg: string) => <FormErrorMessageText children={msg} />}
          />
        </FormControl>
      </Box>
    </>
  );
};

const AccountSetupModal = (props: TCModalProps) => {
  const { isOpen, onClose, tcAccepted, onSuccess, onError, onDecline } = props;
  const [{ csrftoken }] = useCookies(['csrftoken']);
  const toast = useToast();

  const mutation = useMutation(resetPassword, {
    onSuccess,
    onError: ({ response }: AxiosError) => {
      const data = response?.data as any;
      if (response?.status === 400) {
        return toast({
          title: 'Bad request',
          description: data?.errors.join(' ') || 'Invalid request',
          duration: 10000,
          status: 'error',
          isClosable: true,
        });
      }
      return onError();
    },
  });

  const handleSubmit = (values: SetPasswordValues, actions: FormikHelpers<SetPasswordValues>) => {
    if (tcAccepted) values.terms_and_conditions = true;
    mutation.mutate({ values, csrftoken });
    actions.setSubmitting(false);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
          {({ errors, isSubmitting }) => (
            <Form>
              <ModalHeader>Account set up</ModalHeader>
              <ModalBody pb={6}>
                <SetPasswordForm errors={errors} tcAccepted={tcAccepted} />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  fontWeight="normal"
                  fontSize="normal"
                  size="lg"
                  borderRadius={10}
                  mx={3}
                  onClick={() => onDecline()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  fontWeight="normal"
                  fontSize="normal"
                  size="lg"
                  borderRadius={10}
                  disabled={isSubmitting}
                >
                  Continue
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AccountSetupModal;
