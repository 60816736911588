import { Badge, Divider, Flex, Heading, Text } from '@chakra-ui/react';

const RootHeader: React.FC = () => {
  return (
    <Flex
      as="header"
      minWidth="max-content"
      alignItems="center"
      gap={2}
      borderBottomColor="#ECECEE"
      borderBottomWidth={1}
    >
      <Heading as="h1" size="xl" fontWeight="normal" width={'50%'}>
        <Flex
          direction="row"
          alignItems={'center'}
          justifyContent="center"
          width="100%"
          color="primary.600"
          py={5}
          cursor="default"
          userSelect="none"
        >
          <Heading as="h1" size="lg" py={1}>
            CaptivateIQ
          </Heading>
          <Divider orientation="vertical" mx={3} color={'gray.100'} />
          <Text fontWeight="normal" fontSize={'lg'} py={1}>
            Benchmarking
            <Badge as="span" colorScheme="blue" p={1} mx={3}>
              Beta
            </Badge>
          </Text>
        </Flex>
      </Heading>
    </Flex>
  );
};

export default RootHeader;
