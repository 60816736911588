import { Link } from '@chakra-ui/react';

const PrivacyLink = () => {
  return (
    <Link as="a" href="https://www.captivateiq.com/privacy-policy" color={'blue'} isExternal>
      Privacy Policy
    </Link>
  );
};

export default PrivacyLink;
