import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import QuartileBarChart from './QuartileBarChart';

type Quartiles = {
  total: number;
  min: number;
  max: number;
  quartiles: number[];
};

interface PY04Data {
  self: Quartiles;
  items: Quartiles;
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#2D7B81': '#1F5F63',
};

const PY04 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: percentiles,
    error,
    isSuccess,
    isLoading,
  } = useChart<PY04Data | null>('charts/payee/py-04', 'py-04', org.id, chartFilters, survey?.id);
  let maxValue = 0;
  let chartData: BarDatum[] = [];

  if (isSuccess && percentiles) {
    const columns = ['25th Percentile', '50th Percentile', '75th Percentile', '90th Percentile'];
    chartData = columns.map((id, index) => {
      const all = Math.round(percentiles.items.quartiles[index] * 100);
      const self = Math.round(percentiles.self.quartiles[index] * 100);
      maxValue = Math.max(maxValue, all, self);
      return {
        All: all,
        AllColor: '#DBD7D3',
        'My Company': self,
        'My CompanyColor': '#2D7B81',
        id,
      };
    });
  }

  return (
    <BaseChart
      title="How much of target variable compensation are sales teams earning?"
      subtitle="Payout %"
      description="These quartiles show percentage of OTV that's earned by plan participants."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && percentiles) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && percentiles ? (
        <QuartileBarChart
          data={chartData}
          maxValue={maxValue + 10}
          barColors={barColors}
          height={400}
          width={600}
          label={(d) => `${d.value}%`}
          margin={{ top: 0, right: 0, bottom: 100, left: 0 }}
          keys={['All', 'My Company']}
          groupMode="grouped"
          labelSkipWidth={20}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              itemsSpacing: 100,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: 0,
              translateY: 100,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PY04;
