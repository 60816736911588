import { Button, Heading, Table, TableCaption, TableProps, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Survey } from '@/types/Survey';
import { Organization } from '@/types/User';
import { useStickyState, useSurvey } from '../hooks';
import routes from '../routes';
import { toSurveyTitle } from '../utils';

interface SurveyListProps extends TableProps {
  survey_type: string;
}

const SurveyList = (props: SurveyListProps) => {
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [org] = useStickyState<Organization>(null, 'org');
  const { data: surveys, isLoading, isError, isRefetchError, isFetching } = useSurvey(props.survey_type, org?.id);

  useEffect(() => {
    if (survey?.is_accepted) {
      navigate(`${routes.app.base.charts.base}?survey_type=${survey.survey_type}`, {
        state: { survey: survey },
      });
    }
    return () => setSurvey(null);
  }, [navigate, survey]);

  if (isLoading || isFetching) return <Heading>Loading...</Heading>;
  if (isError || isRefetchError) return <Heading>Error loading data</Heading>;
  if (!surveys) return <Heading>No Surveys found</Heading>;

  return (
    <Table variant="simple" {...props}>
      <TableCaption>Survey List</TableCaption>
      <Thead>
        <Tr>
          <Th>Title</Th>
          <Th isNumeric>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {surveys.map((survey) => (
          <Tr key={survey.id}>
            <Td>{toSurveyTitle(survey, org?.name)}</Td>
            <Td isNumeric>
              <Button variant="outline" fontWeight="light" ml="auto" onClick={() => setSurvey(survey)}>
                {survey.is_accepted ? 'View Results' : 'Continue'}
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default SurveyList;
