import { Box, Button, FlexProps, Heading, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import DeleteSurveyModal from '../components/DeleteSurveyModal';
import { useUser } from '../hooks';

const SelfServicePage: React.FC<FlexProps> = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data: user, error, isSuccess, isLoading } = useUser();

  const {
    isOpen: isDeleteSurveyModalOpen,
    onOpen: onDeleteSurveyModalOpen,
    onClose: onDeleteSurveyModalClose,
  } = useDisclosure();

  if (isLoading || !isSuccess) return <Heading>Loading...</Heading>;
  if (error || !user?.is_staff) {
    navigate(-1);
    return null;
  }

  return (
    <VStack pt={10} borderTopWidth={1} borderColor="gray.100">
      <Box onClick={() => navigate(-1)} _hover={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={icon({ name: 'arrow-left' })} />
        <Heading size="sm" fontWeight="medium" as="span" px={3}>
          Back
        </Heading>
      </Box>
      <Heading>Self Service</Heading>
      <Button onClick={onDeleteSurveyModalOpen}>Delete Survey</Button>
      <DeleteSurveyModal
        onClose={onDeleteSurveyModalClose}
        onError={toast}
        onSuccess={() => {
          toast({
            title: 'Success',
            description: 'Survey deleted',
            duration: 10000,
            status: 'success',
            isClosable: true,
          });
          onDeleteSurveyModalClose();
        }}
        isOpen={isDeleteSurveyModalOpen}
        isCentered={true}
        children={null}
      />
    </VStack>
  );
};

export default SelfServicePage;
