import { extendTheme } from '@chakra-ui/react';

import { Button } from './components/Buttons/buttons';
import { CloseButton } from './components/CloseButton/closeButton';
import { Input } from './components/Input/Input';
import { Popover } from './components/Popover/popover';
import { Tag } from './components/Tags/tags';
import { borders } from './foundations/borders/borders';
import { colors } from './foundations/colors/colors';
import { shadows } from './foundations/shadows/shadows';
import {
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  textStyles,
} from './foundations/typography/typography';

const overrides = {
  components: {
    Button,
    CloseButton,
    Popover,
    Tag,
    Input,
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            backgroundColor: 'primary.400',
          },
          border: '2px solid',
          borderColor: '#8795A8',
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
  },
  lineHeights,
  fonts,
  fontSizes,
  letterSpacings,
  textStyles,
  colors,
  shadows,
  borders,
};

export default extendTheme(overrides);
