import { Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import { colors } from '../../theme/foundations/colors/colors';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import WaffleChart from './WaffleChart';

type WaffleDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

interface PL02Data {
  total: number;
  items: {
    [key: string]: number;
  };
  sample: number;
  self: {
    [key: string]: number;
  };
}

const PL02 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: count,
    error,
    isSuccess,
    isLoading,
  } = useChart<PL02Data>('charts/plan/pl-02', 'pl-02', org.id, chartFilters, survey?.id);
  const [waffleData, setWaffleData] = useState<WaffleDatum[]>([]);
  const [myData, setMyData] = useState<WaffleDatum[]>([]);

  useEffect(() => {
    if (isSuccess && count) {
      setWaffleData(
        Object.entries(count.items || [])
          .sort(([aId, aData], [bId, bData]) => bData - aData)
          .map(([id, data], index) => {
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          }),
      );
      setMyData(
        Object.entries(count.self || [])
          .sort(([aId, aData], [bId, bData]) => bData - aData)
          .map(([id, data], index) => {
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          }),
      );
    }
  }, [count, error, isSuccess]);

  return (
    <BaseChart
      title="How frequently does a company pay its sales team?"
      subtitle="Pay frequency"
      description="That payment frequency to plan participants can be very hard to change, especially when the interval is lengthened."
      isLoading={isLoading && waffleData.length === 0}
      hiddenDefinition={!(isSuccess && count) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && count && waffleData.length > 0 ? (
        <Flex alignItems="center" direction="row">
          <Flex direction="column" justifyContent="center">
            <Text fontSize="lg" fontWeight="bold" ml={10} mr={10}>
              All Data
            </Text>
            <WaffleChart
              width={300}
              height={300}
              data={waffleData}
              motionStagger={2}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  itemHeight: 20,
                  itemWidth: 75,
                  itemsSpacing: 0,
                  symbolShape: 'circle',
                  symbolSize: 15,
                  translateX: 0,
                  translateY: -20,
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
              columns={10}
              rows={10}
            />
          </Flex>
          {count.sample > 0 && (
            <Flex direction="column" justifyContent="center">
              <Text fontSize="lg" fontWeight="bold" ml={10} mr={10}>
                My Data
              </Text>
              <WaffleChart
                width={300}
                height={300}
                data={myData}
                motionStagger={2}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    itemHeight: 20,
                    itemWidth: 75,
                    itemsSpacing: 0,
                    symbolShape: 'circle',
                    symbolSize: 15,
                    translateX: 0,
                    translateY: -20,
                  },
                ]}
                margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
                columns={10}
                rows={10}
                axisTop={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'My Data',
                  legendPosition: 'middle',
                  legendOffset: 0,
                }}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <NoDataChart />
      )}
    </BaseChart>
  );
};

export default PL02;
