import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import TransposedBarChart from './TransposedBarChart';

interface PL07Data {
  total: number;
  sample: number;
  self: {
    [key: string]: number;
  };
  items: {
    [key: string]: number;
  };
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#FA8E55': '#D17F4F',
};

const PL07 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: roles,
    error,
    isSuccess,
    isLoading,
  } = useChart<PL07Data | null>('charts/plan/pl-07', 'pl-07', org.id, chartFilters, survey?.id);
  let chartData = [] as BarDatum[];
  let maxValue = 0;

  if (isSuccess && roles) {
    chartData = Object.entries(roles.items)
      .map(([id, data]) => ({
        All: roles.total ? Math.round(((data ?? 0) * 100) / roles.total) : 0,
        AllColor: '#DBD7D3',
        'My Company': roles.sample ? Math.round(((roles.self[id] ?? 0) * 100) / roles.sample) : 0,
        'My CompanyColor': '#FA8E55',
        id: id.split(/[^a-zA-Z -]/)[0].trim(),
      }))
      .sort((a, b) => a.All - b.All);
    maxValue = Math.max(...chartData.map((d) => Math.max(d.All as number, d['My Company'] as number)));
  }

  return (
    <BaseChart
      title="How are the primary activities of plans credited?"
      subtitle="Crediting"
      description="Different crediting rules can help to drive different behaviors for plan participants. Team goals help people to focus on the success of the team in addition to the individual. Product ownerships drives focus on those products, etc."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && roles) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && roles ? (
        <TransposedBarChart
          barColors={barColors}
          data={chartData}
          height={600}
          width={600}
          maxValue={maxValue}
          label={(d) => `${d.value}%`}
          margin={{ top: 0, right: 0, bottom: 50, left: 180 }}
          keys={['All', 'My Company']}
          groupMode="grouped"
          labelSkipWidth={20}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              itemsSpacing: 100,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: -180,
              translateY: 50,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PL07;
