import { Flex, FlexProps } from '@chakra-ui/react';

import SetPasswordForm from '../components/SetPasswordForm';

const SetPasswordPage: React.FC<FlexProps> = () => {
  return (
    <Flex pt={10} justifyContent="center" borderTopWidth={1} borderColor="gray.100">
      <SetPasswordForm width="30%" />
    </Flex>
  );
};

export default SetPasswordPage;
