import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import { colors } from '../../theme/foundations/colors/colors';
import BaseChart from './BaseChart';
import NoDataChart from './NoDataChart';
import WaffleChart from './WaffleChart';

type WaffleDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

interface OG01Data {
  total: number;
  items: {
    [key: string]: number;
  };
  self: string;
}

const OG01 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const {
    data: count,
    error,
    isSuccess,
    isLoading,
  } = useChart<OG01Data>('charts/org/og-01', 'og-01', org.id, chartFilters, survey?.id);
  const [waffleData, setWaffleData] = useState<WaffleDatum[]>([]);

  useEffect(() => {
    if (isSuccess && count) {
      setWaffleData(
        Object.entries(count.items || [])
          .map(([id, data], index) => {
            id = id.replace(' (mine)', '');
            if (id === count.self) id = `${id} (mine)`;
            // @ts-ignore
            const color = colors.qualitative[index + 1];
            return {
              color,
              id,
              label: id,
              value: data,
            };
          })
          .sort((a, b) => b.value - a.value),
      );
    }
  }, [count, error, isSuccess]);

  return (
    <BaseChart
      title="Who is responsible for setting overall sales targets?"
      subtitle="Company-wide targets"
      description="Overall sales targets are the company-wide goals. Typically, these consist of total revenues, new business targets, renewal and upsell targets, etc."
      isLoading={isLoading && waffleData.length === 0}
      hiddenDefinition={!(isSuccess && count) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && count && waffleData.length > 0 ? (
        <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
          <WaffleChart
            width={600}
            height={300}
            data={waffleData}
            motionStagger={2}
            legends={[
              {
                anchor: 'left',
                direction: 'column',
                itemHeight: 20,
                itemWidth: 150,
                itemsSpacing: 20,
                symbolShape: 'circle',
                symbolSize: 18,
                translateX: -150,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 150 }}
          />
        </Flex>
      ) : (
        <NoDataChart />
      )}
    </BaseChart>
  );
};

export default OG01;
