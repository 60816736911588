import { useQuery } from '@tanstack/react-query';

import { User } from '@/types/User';
import api from '../api';
import routes from '../routes';
import { useAuth } from './Auth';

async function fetchUserData(): Promise<User> {
  const response = await api.get(routes.api.user);
  if (response.status !== 200) {
    throw new Error('Error fetching user data');
  }

  return response.data as User;
}

export function useUser() {
  const { logout } = useAuth();
  return useQuery<User, unknown>(['user'], fetchUserData, {
    staleTime: 1000 * 60 * 60, // 1 hour,
    retry: false,
    onSuccess: (user) => (user ? user : logout()),
    onError: () => logout(),
  });
}
