import { Flex, FlexProps, Heading, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HowDoesItWorkModal } from './HowDoesItWorkModal';
import { SurveysTable } from './SurveysTable';
import { WhatAreBenchmarksModal } from './WhatAreBenchmarksModal';

const HomeContent = (props: FlexProps) => {
  const {
    isOpen: isHowItWorksModalOpen,
    onOpen: onHowItWorksModalOpen,
    onClose: onHowItWorksModalClose,
  } = useDisclosure();
  const {
    isOpen: isBenchmarksModalOpen,
    onOpen: onBenchmarksModalOpen,
    onClose: onBenchmarksModalClose,
  } = useDisclosure();

  return (
    <Flex direction="column" width="100%" px={20} {...props}>
      <HStack>
        <Heading size="lg" fontWeight="bold">
          Benchmark Data
        </Heading>
        <FontAwesomeIcon icon={icon({ name: 'circle-exclamation' })} onClick={onBenchmarksModalOpen} cursor="pointer" />
      </HStack>
      <HStack py={3}>
        <Text fontWeight="normal">
          This is a list of your data submissions and their status. Once a data set is complete, you are able to access
          the related benchmarks.
        </Text>
        <Text color="primary.400" onClick={onHowItWorksModalOpen} cursor="pointer">
          How does it work?
        </Text>
      </HStack>
      <SurveysTable py={3} />
      <HowDoesItWorkModal
        isOpen={isHowItWorksModalOpen}
        isCentered={true}
        onClose={onHowItWorksModalClose}
        children={null}
      />
      <WhatAreBenchmarksModal
        isOpen={isBenchmarksModalOpen}
        isCentered={true}
        onClose={onBenchmarksModalClose}
        children={null}
      />
    </Flex>
  );
};

export default HomeContent;
