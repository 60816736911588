import { BarDatum } from '@nivo/bar';
import { useLocation } from 'react-router-dom';

import { Organization } from '@/types/User';
import { useChartFilters } from '../../contexts/ChartFiltersContext';
import { useChart, useStickyState } from '../../hooks';
import BaseChart from './BaseChart';
import NivoTooltip from './NivoTooltip';
import NoDataChart from './NoDataChart';
import TransposedBarChart from './TransposedBarChart';

interface PL09Data {
  labels: string[];
  sample: number;
  total: number;
  self: {
    'Credits quota': {
      [key: string]: number;
    };
    'Fixed amount': {
      [key: string]: number;
    };
  };
  items: {
    'Credits quota': {
      [key: string]: number;
    };
    'Fixed amount': {
      [key: string]: number;
    };
  };
}

const barColors = {
  '#DBD7D3': '#B8B2AF',
  '#E5E4E2': '#CFCDCB',
  '#A7D841': '#8CCB3B',
  '#E6FBBA': '#D9F9A5',
};

const PL09 = () => {
  const [org] = useStickyState<Organization>(null, 'org');
  const location = useLocation();
  const { chartFilters } = useChartFilters();
  const survey = location.state?.survey;
  const { data, error, isSuccess, isLoading } = useChart<PL09Data>(
    'charts/plan/pl-09',
    'pl-09',
    org.id,
    chartFilters,
    survey?.id,
  );
  let chartData = [] as BarDatum[];
  let maxValue = 0;

  if (isSuccess && data) {
    const columns = data.labels;
    chartData = columns
      .map((column) => {
        const credits_quota = Math.round((data.items['Credits quota'][column] * 100) / data.total) || 0;
        const fixed_amount = Math.round((data.items['Fixed amount'][column] * 100) / data.total) || 0;
        const my_credits_quota = Math.round((data.self['Credits quota'][column] * 100) / data.sample) || 0;
        const my_fixed_amount = Math.round((data.self['Fixed amount'][column] * 100) / data.sample) || 0;

        maxValue = Math.max(maxValue, credits_quota + fixed_amount, my_credits_quota + my_fixed_amount);

        return {
          id: column,
          'Credits quota': credits_quota,
          'Credits quotaColor': barColors['#DBD7D3'],
          'Fixed amount': fixed_amount,
          'Fixed amountColor': barColors['#E5E4E2'],
          'My Credits quota': my_credits_quota * -1,
          'My Credits quotaColor': barColors['#A7D841'],
          'My Fixed amount': my_fixed_amount * -1,
          'My Fixed amountColor': barColors['#E6FBBA'],
        };
      })
      .sort((a, b) => a['Credits quota'] + a['Fixed amount'] - (b['Credits quota'] + b['Fixed amount']));
  }

  return (
    <BaseChart
      title="For other than the non-primary activity of a plan, which activities are also included in the plan?"
      subtitle="Secondary activities"
      description="Plans' consideration of non-primary activities help to round out the incentive structure for plan participants. These are often ways to help ensure that the incentives of plan participants line up with the goals of the company."
      isLoading={isLoading}
      hiddenDefinition={!(isSuccess && data) || Boolean(error)}
      error={Boolean(error)}
    >
      {isSuccess && data ? (
        <TransposedBarChart
          barColors={barColors}
          data={chartData}
          height={600}
          width={600}
          maxValue={maxValue}
          minValue={maxValue * -1}
          label={(d) => `${Math.abs(d?.value || 0)}%`}
          tooltip={(data) => <NivoTooltip data={data} symbol="%" absolute />}
          margin={{ top: 0, right: 0, bottom: 50, left: 170 }}
          keys={['Credits quota', 'Fixed amount', 'My Credits quota', 'My Fixed amount']}
          indexBy="id"
          isInteractive={true}
          groupMode="stacked"
          layout="horizontal"
          labelSkipWidth={20}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              itemsSpacing: 150,
              itemWidth: 0,
              itemHeight: 50,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: 'circle',
              translateX: -150,
              translateY: 50,
            },
          ]}
        />
      ) : (
        <NoDataChart title="No Data" />
      )}
    </BaseChart>
  );
};

export default PL09;
