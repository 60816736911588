import { Box as NivoBox } from '@nivo/core';
import { LegendProps } from '@nivo/legends';
// @ts-ignore
import { Waffle } from '@nivo/waffle';
import React from 'react';
// ResponsiveWaffle, ResponsiveWaffleCanvas, ResponsiveWaffleHtml, Waffle, WaffleCanvas, WaffleHtml, canvasDefaultProps, commonDefaultProps, htmlDefaultProps, isDataCell, svgDefaultProps)

import NivoTooltip from './NivoTooltip';

type WaffleDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

interface WaffleChartProps {
  data: WaffleDatum[];
  total?: number;
  columns?: number;
  rows?: number;
  motionStagger?: number;
  legends?: LegendProps[];
  margin?: NivoBox;
  [key: string]: any;
}

const WaffleChart: React.FC<WaffleChartProps> = (props) => {
  return (
    <Waffle
      fillDirection="right"
      borderRadius={50}
      columns={20}
      rows={10}
      padding={2}
      colors={{
        datum: 'color',
      }}
      total={100}
      tooltip={({ data }: any) => <NivoTooltip data={data} symbol="%" />}
      {...props}
    />
  );
};

export default WaffleChart;
