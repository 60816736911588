import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  UseToastOptions,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useCookies } from 'react-cookie';

import api from '../api';
import routes from '../routes';

interface DeleteSurveyModalProps extends Partial<ModalProps> {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
  onError: (options: UseToastOptions) => void;
}

interface DeleteSurveyValues {
  survey_id: number;
  del_template: boolean;
  del_entity: boolean;
  dry_run: boolean;
}

interface SetPasswordRequest {
  values: DeleteSurveyValues;
  csrftoken: string;
}

const initialValues: DeleteSurveyValues = {
  survey_id: 0,
  del_template: false,
  del_entity: false,
  dry_run: false,
};

const deleteSurvey = async ({ values, csrftoken }: SetPasswordRequest) => {
  const { survey_id, del_template, del_entity, dry_run } = values;
  const params = {} as { del_template?: boolean; del_entity?: boolean; dry_run?: boolean };

  if (del_template) params.del_template = true;
  if (del_entity) params.del_entity = true;
  if (dry_run) params.dry_run = true;

  const response = await api.delete(routes.api.ss.survey(survey_id), {
    headers: { 'X-Csrftoken': csrftoken },
    params,
  });

  if (response.status === 204) {
    return response.data;
  }

  throw new Error('Invalid request');
};

const DeleteSurveyModal = (props: DeleteSurveyModalProps) => {
  const { isOpen, onClose, onSuccess, onError } = props;
  const [{ csrftoken }] = useCookies(['csrftoken']);

  const mutation = useMutation(deleteSurvey, {
    onSuccess,
    onError: (error: AxiosError) => {
      if (error.response?.status == 404) {
        onError({
          title: 'Invalid request',
          description: 'Survey Id does not exists',
          duration: 10000,
          status: 'error',
          isClosable: true,
        });
      } else {
        onError({
          title: 'Error',
          description: error.message,
          duration: 10000,
          status: 'error',
          isClosable: true,
        });
      }
      return onClose();
    },
  });

  const handleSubmit = (values: DeleteSurveyValues, actions: FormikHelpers<DeleteSurveyValues>) => {
    mutation.mutate({ values, csrftoken });
    actions.setSubmitting(false);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <ModalHeader>Delete Survey by Id</ModalHeader>
              <ModalBody pb={6}>
                <FormControl id="ss_survey_id_to_delete" mt={4}>
                  <Flex alignItems="center">
                    <FormLabel>Survey Id:</FormLabel>
                    <NumberInput size="md">
                      <Field name="survey_id" as={NumberInputField} type="number" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                </FormControl>

                <Box mt={5}>
                  <FormControl id="ss_del_survey_template" mt={4}>
                    <Field as={Checkbox} name="del_template">
                      <Text as="span">Delete survey template</Text>
                    </Field>
                  </FormControl>
                  <FormControl id="ss_del_survey_entity" mt={4}>
                    <Field as={Checkbox} name="del_entity">
                      <Text as="span">Delete survey entity</Text>
                    </Field>
                  </FormControl>
                  <FormControl id="ss_del_survey_dry_run" mt={4}>
                    <Field as={Checkbox} name="dry_run">
                      <Text as="span">Test run</Text>
                    </Field>
                  </FormControl>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  fontWeight="normal"
                  fontSize="normal"
                  size="lg"
                  borderRadius={10}
                  disabled={isSubmitting}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default DeleteSurveyModal;
