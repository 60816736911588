import { Box, Grid, GridProps } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import LegalFooter from '../components/LegalFooter';
import RootHeader from '../components/RootHeader';

export default function RootLayout(props: GridProps) {
  return (
    <Grid h="100vh" w="100vw" templateColumns="1fr" templateRows="1fr 7fr" {...props}>
      <RootHeader />
      <Box as="div">
        <Box as="div" minHeight="100%">
          <Box as="main" overflow="auto" pb="100px">
            <Outlet />
          </Box>
        </Box>
        <LegalFooter
          as="footer"
          height="100px"
          mt="-100px"
          position={'relative'}
          backgroundColor="white"
          style={{ clear: 'both' }}
        />
      </Box>
    </Grid>
  );
}
