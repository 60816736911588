import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';

export const WhatAreBenchmarksModal = (props: ModalProps) => {
  return (
    <Modal closeOnOverlayClick={true} size="2xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>What are benchmarks?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            <Heading size="normal" fontWeight="bold">
              Organization
            </Heading>
            <Text size="normal" fontWeight="normal" py={3}>
              The Organization benchmarks cover responsibility for different areas of sales compensation, the number of
              plans, plan modifications, etc.
            </Text>
            <Heading size="normal" fontWeight="bold">
              Plan Design
            </Heading>
            <Text size="normal" fontWeight="normal" py={3}>
              The Plan Design benchmarks cover sales team role distribution, plan quotas, measured activities, ramping
              and clawback approaches, etc.
            </Text>
            <Heading size="normal" fontWeight="bold">
              Performance
            </Heading>
            <Text size="normal" fontWeight="normal" py={3}>
              The Performance benchmarks cover ranges of attainment, plan OTV payouts and other questions aggregated
              from individual performance data.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
