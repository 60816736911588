import { Icon, IconProps } from '@chakra-ui/react';

const LoginCornerIcon: React.FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="#E4E2E2"
      fillOpacity={0.5}
      d="m287.036.586-18.6 50.975H161.828l-19.167 52.334H37.301L.1 205.845H363.25l37.653-103.309 37.2-101.95H287.036Z"
    />
  </Icon>
);

export default LoginCornerIcon;
