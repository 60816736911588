import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tag,
  Text,
} from '@chakra-ui/react';

export const HowDoesItWorkModal = (props: ModalProps) => {
  return (
    <Modal closeOnOverlayClick={true} size="2xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How It Works</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            <Tag size="lg" width="70px" backgroundColor="gray.200">
              Open
            </Tag>
            <Text fontWeight="normal" py={3}>
              Open status means that your benchmark data has not been reviewed and accepting into the benchmark dataset.
              Complete your data template and submit to benchmarking@captivateiq.com. Our team will review submitted
              data and confirm completion with you prior to changing the survey state from Open.
            </Text>
            <Tag size="lg" width="110px" backgroundColor="positive.50" textColor="positive.600">
              Completed
            </Tag>
            <Text fontWeight="normal" py={3}>
              Completed status means that your submitted benchmark data has been reviewed and accepted. Completed data
              is loaded into the benchmark dataset every several weeks. Prior to your completed data being added to the
              system, you will be able to see benchmarks, but will not be able to see the computed “My Company”
              comparisons for your company's data.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
