import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Tag: ComponentStyleConfig = {
  variants: {
    secondary: {
      container: {
        bg: 'secondary.200',
        color: 'secondary.900',
      },
    },
    tertiary: {
      container: {
        bg: 'tertiary.200',
        color: 'tertiary.900',
      },
    },
  },
  defaultProps: {
    variant: 'secondary',
  },
};
