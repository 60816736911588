export const fonts = {
  heading: `"Manrope", sans-serif`,
  body: `"Manrope", sans-serif`,
} as const;

export const fontSizes = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  '2xl': '32px',
} as const;

export const lineHeights = {
  '2xs': '16px',
  xs: '20px',
  sm: '22px',
  md: '28px',
  lg: '30px',
  xl: '38px',
  '2xl': '48px',
} as const;

export const letterSpacings = {
  normal: 0,
  wide: '0.4px',
  wider: '0.8px',
} as const;

export const textStyles = {
  /** Page headings */
  headingExtraLarge: {
    fontFamily: fonts.heading,
    fontWeight: 600,
    fontSize: fontSizes['2xl'],
    lineHeight: lineHeights.xl,
    letterSpacing: letterSpacings.normal,
  },
  headingLarge: {
    fontFamily: fonts.heading,
    fontWeight: 600,
    fontSize: fontSizes.xl,
    lineHeight: lineHeights.lg,
    letterSpacing: letterSpacings.normal,
  },
  headingMedium: {
    fontFamily: fonts.heading,
    fontWeight: 600,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.md,
    letterSpacing: letterSpacings.normal,
  },
  headingSmall: {
    fontFamily: fonts.heading,
    fontWeight: 600,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.xs,
    letterSpacing: letterSpacings.normal,
  },
  subheadingLarge: {
    fontFamily: fonts.heading,
    fontWeight: 400,
    fontSize: fontSizes.xl,
    lineHeight: lineHeights.lg,
    letterSpacing: letterSpacings.normal,
  },
  subheadingMedium: {
    fontFamily: fonts.heading,
    fontWeight: 400,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.md,
    letterSpacing: letterSpacings.normal,
  },
  subheadingSmall: {
    fontFamily: fonts.heading,
    fontWeight: 400,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.xs,
    letterSpacing: letterSpacings.normal,
  },
  /** Special display layouts (e.g., payout statements) */
  capsSubheading: {
    fontFamily: fonts.heading,
    fontWeight: 600,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights['2xs'],
    letterSpacing: letterSpacings.wider,
    textTransform: 'uppercase',
  },
  /** Base type style for content */
  bodyRegular: {
    fontFamily: fonts.body,
    fontWeight: 400,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    letterSpacing: letterSpacings.normal,
  },
  /** Placeholder copy */
  bodyItalic: {
    fontFamily: `${fonts.body} italic`,
    fontWeight: 400,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    letterSpacing: letterSpacings.normal,
  },
  /** Input field labels; table headings */
  bodySemiBold: {
    fontFamily: fonts.body,
    fontWeight: 600,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    letterSpacing: letterSpacings.normal,
  },
  /** Captions */
  smallRegular: {
    fontFamily: fonts.body,
    fontWeight: 400,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
    letterSpacing: letterSpacings.normal,
  },
  smallSemiBold: {
    fontFamily: fonts.body,
    fontWeight: 600,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
    letterSpacing: letterSpacings.normal,
  },
  /** Formula syntax */
  monospaceRegular: {
    fontFamily: 'monospace',
    fontWeight: 400,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.xs,
    letterSpacing: letterSpacings.normal,
  },
} as const;
